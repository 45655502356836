<template>
    <Particles id="particles" :options="options" />
</template>

<script>
export default {
    props: {
        disableClick: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        let options = {
            background: {
                color: {
                    // value: '#3c40c6',
                    value: '#000099',
                },
            },
            fullScreen: {
                enable: true,
                zindex: -1,
            },
            fpsLimit: 120,
            interactivity: {
                detectsOn: 'window',
                events: {
                    onClick: {
                        enable: true,
                        mode: 'push',
                    },
                    onHover: {
                        enable: true,
                        mode: 'grab',
                        parallax: {
                            enable: true,
                            force: 20,
                            smooth: 40,
                        },
                    },
                    resize: true,
                },
                modes: {
                    push: {
                        quantity: 0.2,
                    },
                    grab: {
                        distance: 140,
                        links: {
                            blink: false,
                        },
                    },
                },
            },
            particles: {
                color: {
                    value: '#eee',
                },
                links: {
                    color: '#ddd',
                    distance: 150,
                    enable: true,
                    opacity: 0.3,
                    width: 1,
                },
                collisions: {
                    enable: true,
                },
                move: {
                    direction: 'none',
                    enable: true,
                    outMode: 'bounce',
                    random: true,
                    speed: 2,
                    straight: false,
                },
                number: {
                    density: {
                        enable: true,
                        value_area: 2000,
                    },
                    value: 40,
                },
                opacity: {
                    value: 0.4,
                },
                shape: {
                    type: 'circle',
                },
                size: {
                    random: true,
                    value: 4,
                },
            },
            detectRetina: true,
        };
        options.interactivity.events.onClick.enable = !this.disableClick;
        return {
            options,
        };
    },
};
</script>

<style></style>
