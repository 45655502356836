<template>
    <div>
        <particles />
        <div class="container">
            <steps :active="1" />
            <div style="flex-grow:6"></div>
            <div :style="{ width: `${image_width}px`, height: `${image_height}px` }" class="image_container">
                <van-image v-if="src" :src="src" fit="cover" alt="面像采集默认图片" style="width:100%;height:100%"></van-image>
                <svg v-else viewBox="0 0 800 800" style="width:200px;height:200px;opacity:0.8">
                    <path
                        fill="#a5a7f7"
                        d="M689.65,138.49C641.93,88.86,595.57,49,512.13,41.14c-48.81-4.62-89,7.73-112.63,17.18-22.06-10-60.07-23.59-106.89-21-87.32,4.85-147.27,62.82-190.89,105A490.36,490.36,0,0,0,12,260.66l43.9,63c-1,28-5,223.3,147,351.23,22.77,19.17,127.93,107.68,252,89.72C606.26,742.68,739.25,568.85,746.92,337q21.68-38.17,43.33-76.36C766,228.21,713.48,163.27,689.65,138.49Zm52,168c-3.55-8.51-11.81-24.61-29.09-34.36-4.49-2.54-15.65-8.07-47.72-7.64a280.75,280.75,0,0,0-61.09,7.64c-41.54,9.66-72,20.18-80.17,22.9-78.67,26.14-155.41,13.79-169.89,11.46-62.73-10.1-55-28.42-131.71-42-82.68-14.64-118.14,2-135.53,13.36A123.76,123.76,0,0,0,55.9,306.48L36.82,281.66l24.81-26.72c13.88,10,23.83,10.88,30.54,9.54,10.54-2.1,17.64-6.41,26.73-13.36,6.65-5.09,10.53-8.15,11.45-13.36,1.71-9.64-8.22-18-9.54-19.09,11.48,8,20.31,8.12,24.81,7.63,4.79-.51,5.37-1.83,19.09-7.63,16.48-7,20.15-7,22.91-11.46,1.52-2.47,3.88-8.13,0-19.08a28.16,28.16,0,0,0,11.45,11.45c1.58.81,6.24,3,21,1.91,11.89-.87,21.68-1.59,32.45-7.64,5-2.79,9.78-5.57,13.36-11.45a30.06,30.06,0,0,0,3.82-19.09c1.58,4.29,4.81,10.89,11.45,15.27,7.47,4.93,15.35,4.48,24.82,3.82,33.29-2.34,50-3.55,55.36-5.73a91.13,91.13,0,0,0,32.45-22.9c-.36,5.93.14,14.62,5.72,21,6,6.86,13.77,6.31,43.91,7.64,33.34,1.47,39.37,2.82,49.63-1.91a56.51,56.51,0,0,0,21-17.18c-1,4.89-2.46,15.75,3.81,22.91,2.59,2.95,5.59,4.23,24.82,7.63,26.43,4.68,31.64,4.18,34.36,3.82A64.76,64.76,0,0,0,599.94,190c-1.36,3.45-4.85,13.52,0,21,1,1.57,3.59,4.87,22.9,9.55,15.14,3.66,22.71,5.49,30.55,3.81,4.32-.92,12.51-3.43,21-11.45-3.91,3.58-13.22,13.06-11.45,22.91,2.6,14.5,27.7,21.43,45.81,19.09a52.55,52.55,0,0,0,21-7.64l35.62,34.36Q753.5,294.07,741.65,306.48Z"
                    />
                </svg>
                <van-button v-if="!src" style="background:none;border:none" type="success" @click="show_tip = true"> 如何拍舌像 <van-icon name="question-o" style="line-height: 16px;vertical-align: text-top;" /> </van-button>
            </div>
            <div style="flex-grow:1"></div>
            <van-button :type="main_type" :style="{ width: `${button_width}px`, marginTop: '20px' }" class="main_button" @click="clickPrimaryButton" :loading="loading" :loading-text="main_text">{{ main_text }}</van-button>
            <van-button type="primary" @click="clickSecondButton" size="mini" style="background:none;border:none">{{ second_text }}</van-button>
            <div style="flex-grow:3"></div>
            <van-popup v-model:show="show_tip" round position="bottom" style="height:30%;display:flex;flex-direction:column;justify-content:space-between;padding:25px 0" duration="0.15">
                <strong style="text-align:center;font-size:20px">注 意 事 项</strong>
                <span style="padding:30px;font-size:14px;line-height:16px;color:#333">
                    a（自然伸舌，舌体放松，舌面平展，舌尖自然下垂，充分暴露舌体，不可过度用力伸舌）
                    <br/>
                    b（尽量在饭前和服用药物前进行舌象采集）
                    </span>
                <!-- <span style="padding:30px;text-indent:2em;font-size:14px;line-height:16px;color:#333">为确保结果准确，请在自然光环境下拍摄，保持脸部无遮挡并使用素颜</span> -->
                <van-button @click="show_tip = false" type="primary" style="width:90%;margin:0 auto">我知道了</van-button>
            </van-popup>
        </div>
    </div>
</template>

<script>
import particles from '../components/particles.vue';
import steps from '../components/steps';
export default {
    components: {
        particles,
        steps,
    },
    data() {
        return {
            wx: new this.$common.WX(),
            src: '',
            next: false,
            loading: false,
            main_text: '拍摄舌部图像',
            main_type: 'primary',
            second_text: '从相册选择',
            image_width: 0,
            image_height: 0,
            button_width: 0,
            media_id: '',
            show_tip: false,
        };
    },
    methods: {
        clickPrimaryButton() {
            if (this.next) {
                this.$api('analyseTongue', { media_id: this.media_id });
                // this.$router.push('/step3');
                const type = this.$route.query.type;
              
                    this.$router.push({
                        path: "/step3",
                        query: { type: type },
                    });
            
            } else {
                this.loading = true;
                this.main_text = '正在加载...';
                this.second_text = '';
                this.wx
                    .shootImage()
                    .then((local_id) => {
                        this.loading = true;
                        this.main_text = '上传中...';
                        this.second_text = '';
                        this.src = local_id;
                        return this.uploadImage(local_id);
                    })
                    .catch((e) => {
                        this.loading = false;
                        this.main_text = '拍摄舌部图像';
                        this.second_text = '从相册选择';
                    });
            }
        },
        clickSecondButton() {
            if (this.loading) return;
            if (this.next) {
                this.next = false;
                this.main_text = '拍摄舌部图像';
                this.second_text = '从相册选择';
                this.src = '';
            } else {
                this.wx
                    .selectImage()
                    .then((local_id) => {
                        this.loading = true;
                        this.main_text = '上传中...';
                        this.second_text = '';
                        this.src = local_id;
                        return this.uploadImage(local_id);
                    })
                    .catch((e) => {
                        this.loading = false;
                        this.main_text = '拍摄舌部图像';
                        this.second_text = '从相册选择';
                    });
            }
        },
        uploadImage(local_id) {
            this.wx
                .uploadImage(local_id)
                .then((server_id) => {
                    this.main_text = '分析中...';
                    this.media_id = server_id;
                    return this.$api('checkTongue', { media_id: server_id });
                })
                .then((res) => {
                    this.loading = false;
                    if (res.result) {
                        this.next = true;
                        this.main_text = '舌部图像分析完成，进入下一步';
                        this.second_text = '对图片不满意？点击重来';
                        this.main_type = 'success';
                    } else {
                        this.main_text = '图像不合格，请重新拍摄';
                        this.second_text = '从相册选择';
                        this.main_type = 'danger';
                        setTimeout(() => {
                            this.main_type = 'primary';
                            this.main_text = '拍摄舌部图像';
                        }, 1200);
                    }
                })
                .catch((e) => {
                    this.loading = false;
                    this.main_text = '拍摄舌部图像';
                    this.second_text = '从相册选择';
                });
        },
    },
    created() {
        window.onresize = () => {
            // 子元素按父元素比例动态计算宽度，children与parent传值：[min, max]
            // parent：两个临界点父元素宽度，传值格式：[min, max]
            // children：与两个临界点对应的子元素宽度，传值格式：[min, max]
            // min_calc_rule：小于最小宽度后的子元素变化规则：y = (x + b1) * k + b2，传值格式：[b1, k, b2]，默认值
            let calc = (children, parent, min_calc_rule, width = document.body.clientWidth) => {
                // 大于设定最大宽度，返回子元素最大宽度
                if (width > parent[1]) return children[1];
                // 小于设定最小宽度，按计算公式返回子元素宽度，且当width = patent[0]时，计算值与children[0]相等
                if (width < parent[0]) return (width + min_calc_rule[0]) * min_calc_rule[1] + min_calc_rule[2];
                // 介于二者之间，按比例变化
                return ((width - parent[0]) / (parent[1] - parent[0])) * (children[1] - children[0]) + children[0];
            };
            this.image_width = calc([480, 600], [640, 840], [-60, 0.8, 0]);
            this.image_height = (this.image_width / 9) * 16;
            this.button_width = calc([560, 720], [640, 840], [-60, 1, 0]);
        };
        window.onresize();
    },
    mounted() {
        this.show_tip = true;
    },
};
</script>

<style scoped>
.container {
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
}
.image_container {
    margin-top: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(30, 30, 30, 0.4);
    border: 3px #709df1 dashed;
    flex-direction: column;
}
.main_button {
    transition: all 0.35s;
}
</style>
